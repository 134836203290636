var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const easeMap = {
  ease: "cubic-bezier(0.25, 0.1, 0.25, 1)",
  "ease-in": "cubic-bezier(0.42, 0, 1, 1)",
  "ease-in-out": "cubic-bezier(0.42, 0, 0.58, 1)",
  "ease-out": "cubic-bezier(0, 0, 0.58, 1)"
};
exports.default = easeMap;
export default exports;